var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
			'text-ellipsis': _vm.singleLine
		}},[(_vm.textFormatting)?_c('div',[_vm._l((_vm.linkifiedMessage),function(message,i){return [_c(_vm.checkType(message, 'url') ? 'a' : 'span',{key:i,tag:"component",class:{
						'text-ellipsis': _vm.singleLine,
						'text-deleted': _vm.deleted,
						'text-bold': _vm.checkType(message, 'bold'),
						'text-italic': _vm.checkType(message, 'italic'),
						'text-strike': _vm.checkType(message, 'strike'),
						'text-underline': _vm.checkType(message, 'underline'),
						'text-inline-code':
							!_vm.singleLine && _vm.checkType(message, 'inline-code'),
						'text-multiline-code':
							!_vm.singleLine && _vm.checkType(message, 'multiline-code')
					},attrs:{"href":message.href,"target":"_blank"}},[(_vm.deleted)?_c('i',{staticClass:"ri-delete-back-2-fill icon-deleted",attrs:{"name":"deleted"}}):_vm._e(),_vm._v(" "+_vm._s(message.value)+" ")])]})],2):_c('div',[_vm._v(_vm._s(_vm.content))])])}
var staticRenderFns = []

export { render, staticRenderFns }